import { Link } from "@remix-run/react";

const HiringLink = () => {
  return (
    <div className="flex items-center gap-2 justify-center py-8 font-medium">
      <Link
        to="https://jobs.ashbyhq.com/windmill"
        target="_blank"
        className="group flex gap-1 items-bottom"
      >
        <div className="group-hover:underline underline-offset-4 leading-6">
          We&apos;re Hiring — Careers
        </div>
        <div className="font-serif text-green bg-green rounded-full px-2 text-sm leading-6">4</div>
      </Link>
    </div>
  );
};

export default HiringLink;
