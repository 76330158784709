import type { ActionFunctionArgs } from "@remix-run/server-runtime";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

import logger from "@wind/logging";
import { cn, Link } from "@wind/ui";
import { actionHandler } from "@wind/ui/forms/utils.server";

import PublicWaitlistApi from "~/api/PublicWaitlistApi.js";
import {
  CLOUD_DRAWING_1_URL,
  CLOUD_DRAWING_2_URL,
  WINDMILL_DRAWING_URL,
} from "~/components/marketing/images.js";
import { useOptionalAuthUser } from "~/hooks/useAuthUser.js";
import Footer from "~/marketing/components/Footer.jsx";
import { ExternalLinkIcon } from "~/utils/icons.js";
import CardHeader from "./CardHeader.jsx";
import HiringLink from "./HiringLink.jsx";
import ValueProps from "./ValueProps.js";
import WaitlistForm, { WaitlistFormState } from "./WaitlistForm.js";

export const action = async (args: ActionFunctionArgs) => {
  return actionHandler(args, {
    SIGNUP: async ({ data, request }) => {
      // Just in case something bad happens we can grab email from logs
      logger.info("waitlist.signup action started", { data });

      const api = PublicWaitlistApi.forActionRequest(request);
      await api.signup(data.email);

      return {
        result: {
          success: true,
          email: data.email,
        },
      };
    },
    COMPANY_INFO: async ({ request, data }) => {
      // Just in case something bad happens we can grab email from logs
      logger.info("waitlist.companyInfo action started", { data });

      const api = PublicWaitlistApi.forActionRequest(request);
      await api.companyDetails(data);

      return {
        result: {
          success: true,
        },
      };
    },
  });
};

const PmsWaitlist = () => {
  const [formState, setFormState] = useState(WaitlistFormState.SIGNUP);
  const user = useOptionalAuthUser();

  return (
    <div className="lg:pt-24 mx-auto max-w-lg py-4">
      <div className="relative z-0">
        <div
          className={cn(
            "block px-4 transition-all z-10",
            "lg:p-12 lg:bg-surface lg:border-slate-300 lg:border-[0.5px] lg:rounded-[0.75rem] lg:shadow-[0px_1px_1px_1px_rgba(36,36,36,0.12)]"
          )}
        >
          <AnimatePresence>
            {formState === WaitlistFormState.SIGNUP && (
              <motion.div exit={{ opacity: 0, height: 0 }}>
                <CardHeader />
              </motion.div>
            )}
          </AnimatePresence>
          <WaitlistForm formState={formState} setFormState={setFormState} />
          <AnimatePresence>
            {formState === WaitlistFormState.SIGNUP && (
              <motion.div exit={{ opacity: 0, height: 0 }}>
                <ValueProps />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {/* Desktop Clouds */}
        <div className="hidden lg:block absolute inset-0 pointer-events-none">
          <motion.img
            src={CLOUD_DRAWING_1_URL}
            alt="Cloud 1"
            className="absolute lg:top-0 lg:left-0 lg:w-[18%] -z-10 top-14 -right-8 w-[20%]"
            initial={{
              translateX: "0%",
            }}
            animate={{
              translateX: "-60%",
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
            }}
          />
          <motion.img
            src={CLOUD_DRAWING_2_URL}
            alt="Cloud 1"
            className="absolute lg:right-0 lg:top-0 right-24 top-10 z-10"
            initial={{
              translateX: "50%",
              translateY: "-50%",
              width: "16%",
            }}
            animate={{
              translateX: "50%",
              translateY: "-50%",
              width: "18%",
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
            }}
          />
          <img
            src={WINDMILL_DRAWING_URL}
            alt="Windmill"
            className="w-[80%] absolute lg:right-0 lg:top-0 lg:translate-x-[60%] lg:translate-y-[18rem] -z-10 bottom-0 translate-y-[100%]"
          />
        </div>
      </div>
      <AnimatePresence>
        {formState === WaitlistFormState.SIGNUP && (
          <motion.div exit={{ opacity: 0 }}>
            <div className="relative z-10">
              <HiringLink />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {/* Mobile Clouds */}
      <div className="lg:hidden absolute inset-0 -z-10 pointer-events-none overflow-hidden">
        <motion.img
          src={CLOUD_DRAWING_1_URL}
          alt="Cloud 1"
          className="absolute -z-10 top-14 -right-8 w-[20%]"
          animate={{
            opacity: formState === WaitlistFormState.SIGNUP ? 1 : 0,
          }}
        />
        <motion.img
          src={CLOUD_DRAWING_2_URL}
          alt="Cloud 2"
          className="absolute lg:right-0 lg:top-0 right-12 top-10 z-10 w-1/3"
          animate={{
            opacity: formState === WaitlistFormState.SIGNUP ? 1 : 0,
          }}
        />
        <motion.img
          src={WINDMILL_DRAWING_URL}
          animate={{
            opacity: formState === WaitlistFormState.SIGNUP ? 1 : 0,
          }}
          alt="Windmill"
          className="absolute top-[80%] w-[50%] right-0"
        />
      </div>
      {/* Login/Logout */}
      <AnimatePresence>
        {formState === WaitlistFormState.SIGNUP && (
          <motion.div className="absolute right-6 top-4" exit={{ opacity: 0 }}>
            {!user && (
              <Link
                to="/auth"
                className="hover:underline underline-offset-4 font-medium flex gap-2 items-center"
              >
                Login
                <ExternalLinkIcon />
              </Link>
            )}
            {user && (
              <Link
                to="/s"
                className="hover:underline underline-offset-4 font-medium flex gap-2 items-center"
              >
                Open Windmill
                <ExternalLinkIcon />
              </Link>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      <Footer />
    </div>
  );
};

export default PmsWaitlist;
