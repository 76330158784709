import type { FormMutationState } from "@wind/ui";
import { FormFieldIdentifier, useFieldIssue } from "@wind/ui";

import SubmitButton from "./SubmitButton.js";

interface Props {
  mutation: FormMutationState;
}

const WaitlistEmailFormContents = ({ mutation }: Props) => {
  const issue = useFieldIssue("email");

  return (
    <div className="py-8">
      <FormFieldIdentifier name="email" type="email" />
      <div className="border bg-surface rounded-full p-1 flex focus-within:ring-1 ring-offset-2 ring-offset-white focus-within:ring-blue-400 transition-all duration-200">
        <input
          name="email"
          type="email"
          className="border-none bg-transparent w-full focus:outline-none focus:ring-0 placeholder:text-primary pl-4 rounded-l-full overflow-hidden text-sm lg:text-base"
          placeholder="Enter your work email"
          required
        />
        <SubmitButton isLoading={mutation.mutating}>Join the Waitlist</SubmitButton>
      </div>
      {issue && <p className="mt-2 text-sm text-red">{issue.message}</p>}
    </div>
  );
};

export default WaitlistEmailFormContents;
