import { AnimatePresence, motion } from "framer-motion";

import {
  HiddenFormInput,
  MutationForm,
  SelectInput,
  TextAreaInput,
  TextInput,
  useFormMutation,
  VStack,
} from "@wind/ui";

import SubmitButton from "./SubmitButton.js";
import WaitlistEmailFormContents from "./WaitlistEmailFormContents.jsx";

export enum WaitlistFormState {
  SIGNUP = "SIGNUP",
  COMPANY_INFO = "COMPANY_INFO",
  COMPLETED = "COMPLETED",
}

const FORM_DURATION_TRANSITION = {
  duration: 0.3,
  ease: "easeInOut",
};

interface Props {
  formState: WaitlistFormState;
  setFormState: (state: WaitlistFormState) => void;
}

const WaitlistForm = ({ formState, setFormState }: Props) => {
  const companyInfoMutation = useFormMutation("COMPANY_INFO", {
    handlers: {
      onSuccess: () => {
        setFormState(WaitlistFormState.COMPLETED);
      },
    },
  });

  const signupMutation = useFormMutation("SIGNUP", {
    handlers: {
      onSuccess: () => {
        setFormState(WaitlistFormState.COMPANY_INFO);
        setTimeout(() => {
          companyInfoMutation.methods.setFocus("companyName");
        }, 200);
      },
    },
  });

  return (
    <div>
      <AnimatePresence initial={false} mode="popLayout">
        {formState === WaitlistFormState.SIGNUP && (
          <motion.div exit={{ opacity: 0, height: 0 }} transition={FORM_DURATION_TRANSITION}>
            <MutationForm {...signupMutation}>
              <WaitlistEmailFormContents mutation={signupMutation} />
            </MutationForm>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence initial={false} mode="popLayout">
        {formState === WaitlistFormState.COMPANY_INFO && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="overflow-y-hidden overflow-x-visible"
            transition={FORM_DURATION_TRANSITION}
          >
            <VStack>
              <div className="text-md bg-blue-50 text-blue-700 rounded-lg p-4 border border-blue-300">
                Thanks for signing up for the Windmill waitlist. We&apos;d love to learn a bit more
                about your company.
              </div>
              <MutationForm {...companyInfoMutation}>
                <HiddenFormInput
                  name="email"
                  value={signupMutation.resultData.email}
                  type="email"
                />
                <TextInput
                  name="companyName"
                  label="Company Name"
                  placeholder="Your Company Name"
                  required
                />
                <SelectInput
                  name="companySize"
                  label="Company Size"
                  triggerClassName="w-full"
                  placeholder="Select Company Size"
                  options={[
                    { label: "1-10", value: "1-10" },
                    { label: "11-50", value: "11-50" },
                    { label: "51-250", value: "51-250" },
                    { label: "250+", value: "250+" },
                    { label: "1000+", value: "1000+" },
                  ]}
                />
                <TextInput
                  name="companyWebsite"
                  label="Company Website"
                  placeholder="www.yourcompany.com"
                />
                <TextAreaInput
                  name="howDidYouHear"
                  label="How did you hear about Windmill?"
                  rows={2}
                />
                <SubmitButton isLoading={companyInfoMutation.mutating}>Submit</SubmitButton>
              </MutationForm>
            </VStack>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence initial={false} mode="popLayout">
        {formState === WaitlistFormState.COMPLETED && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="overflow-y-hidden"
            transition={FORM_DURATION_TRANSITION}
          >
            <div className="text-xl font-serif font-medium">
              Thanks for signing up! We&apos;ll be in touch.
            </div>
            <button
              className="mt-8 text-center block underline underline-offset-2 hover:text-gray-700 transition-colors"
              onClick={() => { setFormState(WaitlistFormState.SIGNUP); }}
            >
              Go Back Home
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default WaitlistForm;
